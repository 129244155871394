var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "search", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "c-tree-table",
              {
                attrs: {
                  title: "장소 목록",
                  parentProperty: "upMdmLocationId",
                  customID: "mdmLocationId",
                  columns: _vm.grid.columns,
                  data: _vm.grid.data,
                  columnSetting: false,
                  isFullScreen: false,
                  usePaging: false,
                },
                on: { rowClick: _vm.rowClick },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "LBLSEARCH", icon: "search" },
                          on: { btnClicked: _vm.getList },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
          [
            _c(
              "q-form",
              { ref: "editForm" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBLDETAIL" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREG", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.inputEditable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.location,
                                    mappingType: _vm.saveType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.inputEditable,
                              label: "장소명",
                              name: "locationName",
                            },
                            model: {
                              value: _vm.location.locationName,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "locationName", $$v)
                              },
                              expression: "location.locationName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c(
                            "c-location",
                            {
                              attrs: {
                                type: "edit",
                                editable: _vm.inputEditable,
                                label: "상위 장소",
                                nodateLabel: "최상위장소",
                                name: "upMdmLocationId",
                                multiple: "single",
                              },
                              model: {
                                value: _vm.location.upMdmLocationId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.location, "upMdmLocationId", $$v)
                                },
                                expression: "location.upMdmLocationId",
                              },
                            },
                            [_vm._v(" > ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.inputEditable,
                              rows: 4,
                              label: "비고",
                              name: "remarks",
                            },
                            model: {
                              value: _vm.location.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "remarks", $$v)
                              },
                              expression: "location.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.inputEditable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.location.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "plantCd", $$v)
                              },
                              expression: "location.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable: _vm.inputEditable,
                              isFlag: true,
                              label: "사용여부",
                              name: "useFlag",
                            },
                            model: {
                              value: _vm.location.useFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "useFlag", $$v)
                              },
                              expression: "location.useFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.inputEditable,
                              type: "number",
                              label: "정렬순서",
                              name: "sortOrder",
                            },
                            model: {
                              value: _vm.location.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.location, "sortOrder", $$v)
                              },
                              expression: "location.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }